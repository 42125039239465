import React from "react"
import Layout from '../components/layout'
import ContactBox from '../components/contact-box'
import SEO from '../components/seo'


export default function Contact() {
  return (
    <>
    <Layout>

      <SEO 
        title="Contact"
        description="You used to call me on my cell phone. Late night when you need my love. And I know when that hotline bling. That can only mean one thing."
      />

      <div className="page-heading-box">
        <h1>CONTACT</h1>
      </div>
      
      <ContactBox />

    </Layout>
    </>
  )
}