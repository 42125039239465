import React from 'react'
import "./contact-box.css"


const ContactBox = () => {
  return (

    <div className="contact-form-container">
        <form className="contact-form" name="contact" method="POST" data-netlify="true">
          <p>
            <label htmlFor="name">Name <input className="contact-form-input-field" id="name" type="text" name="name"/></label>
          </p>
          <p>
            <label htmlFor="email">Email <input className="contact-form-input-field" id="email" type="email" name="email"/></label>
          </p>
          <p>
            <label htmlFor="message">Message <textarea className="contact-form-input-field" id="message" type="text" name="message"/></label>
          </p>
          <p>
            <button className="btn" type="submit">Send</button>
          </p>
        </form>
      </div>

  )
}

export default ContactBox